
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const handleExportToPDF = (clients) => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const doc = new jsPDF(orientation, unit, size);
  
    // Add header
    doc.setFontSize(16);
    doc.text('Clients Information', 40, 30);
  
    // Add date-time
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
    doc.setFontSize(12);
    doc.text(`Exported on: ${formattedDate}`, 40, 50);
    doc.text(`Total no of users: ${clients.length}`, 40, 70);
  
    // Add table
    doc.autoTable({
      head: [['ID', 'First Name', 'Last Name', 'Agre. Date', 'Agre. Expiry Date', 'Society', 'Flat No.', 'Status']],
      body: clients.map(client => [
        client.id,
        client.firstName,
        client.lastName,
        client.agreementDate,
        client.agreementExpiryDate,
        client.societyName,
        client.flatNumber,
        client.status,
      ]),
      startY: 90, // Adjust the Y position as needed
    });
  
    doc.save('clients.pdf');
  };