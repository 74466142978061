import { supabase } from "../configs/supabase";
import { getUserId, isAuthenticated } from "../utils/auth";

const validateAuth = ()=>{
    if(!isAuthenticated()){
        window.location.href = "/login"
    }
    return;
}
export const getAllClients = async () => {
    validateAuth()
    return await supabase
        .from('clients')
        .select('*')
        .eq('broker_id', getUserId())
}

export const getClient = async (id) => {
    validateAuth()
    return await supabase
        .from('clients')
        .select('*')
        .eq('id', id)
        .single();
}

export const createClient = async (formData) => {
    return await supabase.from('clients').insert(formData);
}

export const updateClient = async (id, formData) => {
    validateAuth()
    return await supabase
        .from('clients')
        .update(formData)
        .eq('id', id)
}

export const getClientByExpiryDateRange = async ({ startDate, endDate }) => {
    validateAuth()
    return await supabase
        .from('clients')
        .select('*')
        .gte('agreementExpiryDate', startDate)
        .lte('agreementExpiryDate', endDate);
}

export const deleteClient = async (id) => {
    validateAuth()
    return await supabase
        .from('clients')
        .delete()
        .eq('id', id)
}