import React, { useEffect, useState } from 'react';
import { Tooltip, TablePagination, Table, TableRow, TableCell, TableHead, TableBody, Button, TextField, Grid, Typography, Paper, FormControl } from '@mui/material';
import { Edit, Group, PictureAsPdf, Search, SwapVert, ExpandMore } from '@mui/icons-material';

import { getAllClients, getClientByExpiryDateRange } from '../services/clients';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleExportToPDF } from '../utils/common';

const ClientTable = () => {
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const tableCellStyle = { fontSize: '1.1em' }
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleExportToPDFClick = () => {
    handleExportToPDF(filteredClients)
  };

  useEffect(() => {
    if (clients.length === 0) {
      getAllClients()
        .then(res => {
          setClients(res.data);
        })
        .catch(error => {
          toast("Something went wrong, please refresh page again!!");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedClients = [...clients].sort((a, b) => {
    if (sortConfig.key !== null) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredClients = sortedClients.filter((client) =>
    Object.values(client).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(search.toLowerCase())
    )
  );

  const [formData, setFormData] = useState({
    startDate: '',
    endDate: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    getClientByExpiryDateRange(formData)
      .then(res => {
        toast("Success");
        setClients(res.data);
      })
      .catch(error => {
        alert("Something went wrong, please refresh page again!!");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleDeleteClient = async (e) => {
  //   e.preventDefault();
  //   const confirmDelete = window.confirm('Are you sure you want to delete?');
  //   if (confirmDelete) {
  //     const clientId = e.currentTarget.getAttribute('value');
  //     deleteClient(clientId)
  //       .then(res => {
  //         toast.success("Client Deleted Successfully!!", {
  //           autoClose: false,
  //           onClose: () => {
  //             setClients([]);
  //           }
  //         });
  //       })
  //       .catch(error => {
  //         toast("Something went wrong, please try again!!");
  //       });
  //   }
  // };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '200px', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ textAlign: 'left', color: '#2196f3', fontWeight: 'bold' }}>
            All Clients Info
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid xs={10} item md={10}>
              <Grid container>
                <Grid item md={2}>
                  <label htmlFor="startDate">Start Date</label>
                  <FormControl>
                    <TextField
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      required
                      sx={{ mr: 2, mt: 1, height: "1px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <label htmlFor="endDate">End Date</label>
                  <FormControl fullWidth>
                    <TextField
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      required
                      sx={{ mt: 1,height: "1px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <Button variant="contained" type="submit" sx={{ marginTop: "30px", padding: "10px" }}>
                    <Search />Search
                  </Button>
                  <Button variant="contained" onClick={() => window.location.reload()} sx={{ marginTop: "30px", marginLeft: "10px", padding: "10px" }}>
                    <Group /> Show All
                  </Button>
                  <Button variant="contained" onClick={handleExportToPDFClick} sx={{ marginTop: "30px", marginLeft: "10px", padding: "10px" }}>
                    <PictureAsPdf /> Download PDF
                  </Button>
                </Grid>
                <Grid item xs={4}>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid sx={{ marginTop: "20px" }} item md={10}>
            <TextField
              type="text"
              placeholder="Search... by client or flat name"
              onChange={(e) => setSearch(e.target.value)}
              className="mb-2"
              fullWidth
              autoComplete='off'
            />
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredClients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table sx={{ minWidth: 650, backgroundColor: '#f0f0f0', border: '1px solid #ddd' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#b8d5e3', color: '#ffffff', border: '1px solid #ddd' }}>
                <TableCell style={tableCellStyle}>ID</TableCell>
                <TableCell style={tableCellStyle} onClick={() => handleSort('firstName')}><SwapVert /> Full Name</TableCell>
                <TableCell style={tableCellStyle} onClick={() => handleSort('agreementDate')}><SwapVert /> Agre. Date</TableCell>
                <TableCell style={tableCellStyle} onClick={() => handleSort('agreementExpiryDate')}><SwapVert /> Agre. Expiry Date</TableCell>
                <TableCell style={tableCellStyle}>Address</TableCell>
                <TableCell style={tableCellStyle}>Society</TableCell>
                <TableCell style={tableCellStyle}>Flat No.</TableCell>
                <TableCell style={tableCellStyle}>Remark</TableCell>
                <TableCell style={tableCellStyle}>Edit</TableCell>
                <TableCell style={tableCellStyle}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((client, index) => (
                  <TableRow
                    key={client.id}
                    sx={{ border: '1px solid #ddd', backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}
                  >

                    <TableCell>{client.id}</TableCell>
                    <TableCell>{client.firstName + " " + client.lastName}</TableCell>
                    <TableCell>{client.agreementDate}</TableCell>
                    <TableCell>{client.agreementExpiryDate}</TableCell>
                    <TableCell>
                      <Tooltip title={client.address}>
                        <span>{client.address.slice(0, 10)}</span><ExpandMore />
                      </Tooltip>

                    </TableCell>
                    <TableCell>{client.societyName}</TableCell>
                    <TableCell>{client.flatNumber}</TableCell>
                    <TableCell>{client.remark}</TableCell>
                    <TableCell>
                      <Link to={`/edit-client/${client.id}`}>
                        <Edit />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color={client.status === "active" ? "success" : "error"}>
                        {client.status}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid >
    </Paper>
  );
};

export default ClientTable;
