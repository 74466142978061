export const setCookie = (name, value, minutes) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (minutes * 60 * 1000)); // Convert minutes to milliseconds

    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}


export const isAuthenticated = () => {
    const name = "user-auth";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie contains the name we're looking for
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1) && true; // Return the value of the cookie
        }
    }
    return false; // Return null if the cookie with the specified name is not found
}

export const getUserName = () => {
    const name = "user-auth";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie contains the name we're looking for
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1); // Return the value of the cookie
        }
    }
    window.location.href = "/login"
    return false; // Return null if the cookie with the specified name is not found
}
export const getUserId = () => {
    const name = "id";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie contains the name we're looking for
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1); // Return the value of the cookie
        }
    }
    window.location.href = "/login"
    return false; // Return null if the cookie with the specified name is not found
}
export const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Adding leading zero if needed

    return `${year}-${month}`;
}