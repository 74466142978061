import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Sidebar from './components/Sidebar';
import ClientForm from './components/ClientForm';
import ClientTable from './components/ClientTable';
import Login from './components/Login';
import { isAuthenticated } from './utils/auth';
import UpdateClientForm from './components/UpdateClientForm';
// import Header from './components/Header';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import "bootstrap/dist/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/error/ErrorBoundary';
import AppHeader from './components/AppHeader';
const App = () => {
  const isAuth = isAuthenticated()
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  if (!isAuth) {
    // window.location.pathname = "/login";
    return <Login />
  }
  return (
    <ErrorBoundary>
      <>
        <AppHeader />
        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header />
          </Grid>
        </Grid> */}

        <Grid container spacing={2} style={{ marginTop: "15px" }}>
          {/* <Grid item xs={2} >
            <Sidebar />
          </Grid> */}
          <Grid item xs={12} style={{ marginTop: "5px" }}>
            <Item>
              <Router>
                <Routes>
                  <Route path="/home" element={<ClientTable />} />
                  <Route path="/clients" element={<ClientTable />} />
                  <Route path="/add-client" element={<ClientForm />} />
                  <Route path="/edit-client/:id" element={<UpdateClientForm />} />
                </Routes>
              </Router>
            </Item>
          </Grid>
        </Grid>
      </>
    </ErrorBoundary>
  );


};

export default App;