import React, { useState, useEffect } from 'react';
import { Paper, Container, Typography, Grid, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { useParams } from 'react-router';
import { getClient, updateClient } from '../services/clients';

const UpdateClientForm = () => {
    const [formData, setFormData] = useState({
        brokerName: '',
        firstName: '',
        lastName: '',
        agreementDate: '',
        agreementExpiryDate: '',
        societyName: '',
        address: '',
        flatNumber: '',
        remark: '',
        status: ''
    });
    const [isLoading, setLoader] = useState(false);
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            // getClientDetails();
            getClient(id)
                .then(({ data }) => {
                    if (data) {
                        setFormData(data);
                    } else {
                        alert('Something went wrong, please try again!!');
                    }
                })
                .catch(error => {
                    console.error('Error fetching client details:', error);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    }, [id]); // Only execute when clientId changes


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        // Add your form submission logic here
        updateClient(id, formData)
            .then(({ status }) => {
                setLoader(false);
                if (status === 204) {
                    alert('Client updated successfully!!');
                    window.location.href = '/home';
                } else {
                    alert('Something went wrong, please try again!!');
                }
            })
            .catch(error => {
                setLoader(false);
                alert('Something went wrong, please try again!!');
            });
    };

    const handleReset = () => {
        // Reset form fields to their original values
        setFormData({
            brokerName: '',
            firstName: '',
            lastName: '',
            agreementDate: '',
            agreementExpiryDate: '',
            societyName: '',
            address: '',
            flatNumber: '',
            remark: '',
            status: ''
        });
    };

    return (
        <Paper elevation={3} style={{ marginTop: '15px', display: 'flex', justifyContent: 'center',marginBottom:"100px" }}>
            <Container className="py-1">
                <Grid container justifyContent="center">
                    <Grid item xs={10} md={10}>
                        <Typography variant="h6" gutterBottom style={{ color: '#2196f3', fontWeight: 'bold', }}>
                            Update Client Info
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        variant="outlined"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            label="Status"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleChange}
                                            required
                                        >
                                            <MenuItem value="active">Active</MenuItem>
                                            <MenuItem value="expired">Expired</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="Date of Agreement"
                                        variant="outlined"
                                        type="date"
                                        name="agreementDate"
                                        value={formData.agreementDate}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="Agreement Expiry Date"
                                        variant="outlined"
                                        type="date"
                                        name="agreementExpiryDate"
                                        value={formData.agreementExpiryDate}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="Society Name"
                                        variant="outlined"
                                        name="societyName"
                                        value={formData.societyName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        label="Flat Number"
                                        variant="outlined"
                                        name="flatNumber"
                                        value={formData.flatNumber}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    variant="outlined"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                    margin="normal"
                                />
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Broker Name"
                                        variant="outlined"
                                        name="brokerName"
                                        value={formData.brokerName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Remark"
                                        variant="outlined"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isLoading}
                                style={{ marginTop: '20px' }}
                            >
                                {isLoading && <CircularProgress size={24} style={{ marginRight: '10px' }} />}
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                type="reset"
                                style={{ marginTop: '20px', marginLeft: '10px' }}
                                onClick={handleReset} // Call handleReset on button click
                            >
                                Reset
                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};

export default UpdateClientForm;
