import React, { useState } from 'react';
import { Container, Typography, Grid, TextField, Button, CircularProgress, Paper } from '@mui/material';
// import { supabase } from '../configs/supabase';
import { getUserId } from '../utils/auth';
import { createClient } from '../services/clients';

const ClientForm = () => {

    const initialFormData = {
        brokerName: '',
        firstName: '',
        lastName: '',
        agreementDate: '',
        agreementExpiryDate: '',
        societyName: '',
        address: '',
        flatNumber: '',
        remark: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setLoader] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        formData.broker_id = getUserId();
        createClient(formData)
            .then(({ status }) => {
                setLoader(false);
                if (status === 201) {
                    alert('Client created successfully!!');
                    window.location.href = '/home';
                } else {
                    alert('Something went wrong, please try again!!');
                }
            })
            .catch(error => {
                setLoader(false);
                alert('Something went wrong, please try again!!');
            });
    };

    const handleReset = () => {
        setFormData(initialFormData); // Reset the form data to initial state
    };

    return (
        <Paper elevation={3} style={{ marginTop: '15px', display: 'flex', justifyContent: 'center',marginBottom:"100px" }}>
            <Container className="py-1">
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'center', color: '#2196f3', fontWeight: 'bold', }}>
                            Add Client Info
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={6}>

                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        variant="outlined"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Date of Agreement"
                                        variant="outlined"
                                        type="date"
                                        name="agreementDate"
                                        value={formData.agreementDate}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Agreement Expiry Date"
                                        variant="outlined"
                                        type="date"
                                        name="agreementExpiryDate"
                                        value={formData.agreementExpiryDate}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Flat Number"
                                        variant="outlined"
                                        name="flatNumber"
                                        value={formData.flatNumber}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        label="Society Name"
                                        variant="outlined"
                                        name="societyName"
                                        value={formData.societyName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                fullWidth
                                label="Address"
                                variant="outlined"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                                margin="normal"
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Broker Name"
                                        variant="outlined"
                                        name="brokerName"
                                        value={formData.brokerName}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        label="Remark"
                                        variant="outlined"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleChange}
                                        required
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isLoading}
                                    style={{ marginTop: '20px' }}
                                >
                                    {isLoading && <CircularProgress size={24} style={{ marginRight: '10px' }} />}
                                    Submit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    type="reset"
                                    style={{ marginTop: '20px', marginLeft: '10px' }}
                                    onClick={handleReset} // Call handleReset on button click
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};

export default ClientForm;
